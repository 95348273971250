import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { ChangeEvent, useState } from 'react'
import SEO from '../components/atoms/seo'
import OfficialContactLayout from '../components/organisms/officialContactLayout'
import OfficialSupport01 from '../components/organisms/officialSupport01'
import OfficialSupport02 from '../components/organisms/officialSupport02'
import OfficialSupport03 from '../components/organisms/officialSupport03'
import useApi from '../hooks/useApi'
import useKindName from '../hooks/useKindName'
import BreakPoints from '../styles/breakPoints'
import { IContactSupport } from '../types/Contact'
import { DeviceKindList } from '../types/DeviceKind'
import { SupportKindList } from '../types/SupportKind'
import StringUtil from '../utils/StringUtil'

const Wrapper = styled.main`
  font-size: 16px;
  .p-head-inner {
    .p-nav-area {
      display: none;
    }
  }
  ${BreakPoints.xLarge} {
    .p-head-inner {
      .p-nav-area {
        display: flex;
      }
    }
  }
`
type IPageType = 'input' | 'confirm' | 'complete'
const InitContact: IContactSupport = {
  agree: false,
  body: '',
  company: '',
  companyId: '',
  deviceKind: '',
  email: '',
  error: '',
  name: '',
  supportKind: '',
  tel: '',
}

/**
 * サポート用お問い合わせページTOP
 * @constructor
 */
const SupportPage = () => {
  const [pageType, setPageType] = useState<IPageType>('input')
  const [contact, setContact] = useState<IContactSupport>(InitContact)
  const [isSending, setSending] = useState(false)
  const getSupportName = useKindName(SupportKindList)
  const getDeviceName = useKindName(DeviceKindList)
  const { execApi } = useApi()

  /** データ変更時処理 */
  const onChange =
    (key: keyof IContactSupport) =>
    (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
      let val: string | boolean = e.target.value
      if (key === 'tel') val = StringUtil.pickNumberHyphen(val)
      else if (key === 'agree' && e.target instanceof HTMLInputElement)
        val = e.target.checked
      setContact({ ...contact, [key]: val })
    }

  /**
   * 送信ボタンクリック時処理
   */
  const onClickSend = () => {
    setSending(true)
    const request = {
      ...contact,
      supportKind: getSupportName(contact.supportKind),
      deviceKind: getDeviceName(contact.deviceKind),
    }
    execApi(
      '/support',
      request,
      () => {
        setPageType('complete')
      },
      () => {
        setSending(false)
      }
    )
  }

  /** ページタイプを更新 */
  const onChangePageType = (type: IPageType) => () => {
    window.scroll({ top: 0, behavior: 'auto' })
    setPageType(type)
  }

  return (
    <OfficialContactLayout>
      <SEO title="ご利用サポート" />
      <Wrapper>
        {/** 入力用 */}
        {pageType === 'input' && (
          <OfficialSupport01
            contact={contact}
            onChange={onChange}
            onConfirm={onChangePageType('confirm')}
          />
        )}
        {/** 確認用 */}
        {pageType === 'confirm' && (
          <OfficialSupport02
            isSending={isSending}
            contact={contact}
            onClickBack={onChangePageType('input')}
            onClickSend={onClickSend}
          />
        )}
        {/** 完了用 */}
        {pageType === 'complete' && <OfficialSupport03 />}
      </Wrapper>
    </OfficialContactLayout>
  )
}

export default SupportPage

export const pageQuery = graphql`
  query SupportPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`
