import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React, { ChangeEvent, useState } from 'react'
import BreakPoints from '../../styles/breakPoints'
import { IContactSupport } from '../../types/Contact'
import { DeviceKindList } from '../../types/DeviceKind'
import { SupportKindList } from '../../types/SupportKind'
import StringUtil from '../../utils/StringUtil'
import CommonCheckbox from '../atoms/commonCheckbox'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import ContactFlow from '../molecules/contactFlow'
import OfficialLowHeader from './officialLowHeader'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;
  .p-section-inner {
    max-width: 620px;
    padding: 60px 0;
    margin: 0 auto;
    .p-section-contact {
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .item-wrap {
        margin: 0px 0px 20px;
        &.name-wrap {
          display: flex;
          div {
            width: 50%;
            margin: 0;
          }
        }
        .label-area {
          display: flex;
          flex-wrap: wrap;
          padding: 0 0 2px;
          .txt-color-alert {
            margin: 0 5px;
            &.require {
              opacity: 0.6;
            }
            &.err {
              margin-left: auto;
              word-break: keep-all;
            }
          }
          .sub-description {
            margin: 0 5px;
            text-align: left;
          }
        }
        textarea {
          min-height: 120px;
        }
      }
    }
    .p-top-data-wrap {
      background: #f9f3c4;
      width: 100%;
      border-radius: 8px;
      margin: -30px auto 60px;
      padding: 20px;
      text-align: center;
      p {
        margin: 0 0 20px;
      }
      button {
        margin: 0;
      }
      hr {
        margin: 20px 0;
        border: solid 1px #17ba9a;
      }
    }
    .p-select {
      overflow: hidden;
      width: 90%;
      text-align: center;
      position: relative;
      border: solid 2px #ebebeb;
      background: #ffffff;
      width: 100%;
      min-height: 40px;
      border-radius: 8px;
      transition: 0.3s;
      &:hover {
        border-color: #3ec7b3;
      }
      &:before {
        position: absolute;
        top: 45%;
        right: 0.8em;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #272d2c;
        pointer-events: none;
      }
      select {
        width: 100%;
        padding-right: 1em;
        cursor: pointer;
        text-indent: 0.01px;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: transparent;
        background-image: none;
        box-shadow: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 8px 38px 8px 8px;
        &:-ms-expand {
          display: none;
        }
      }
    }
  }
  ${BreakPoints.large} {
    .p-section-inner {
      .p-section-contact {
      }
    }
  }
`

type IProps = {
  /** お問い合わせ情報 */
  contact: IContactSupport
  /** データ変更時コールバック */
  onChange: (
    key: keyof IContactSupport
  ) => (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void
  /** 確認ボタンクリック時処理 */
  onConfirm: () => void
}

/**
 * 利用ユーザ用お問い合わせ-入力画面
 * @constructor
 */
const OfficialSupport01 = ({ contact, onConfirm, onChange }: IProps) => {
  const [isError, setError] = useState(false)
  const routeParam = useRouteParam('/help')
  const routeParam2 = useRouteParam('/help/faq')

  /** 確認ボタンクリック時処理 */
  const onClick = () => {
    if (
      !contact.company ||
      !contact.companyId ||
      !contact.name ||
      !contact.email ||
      !StringUtil.checkFormatMail(contact.email) ||
      !contact.agree ||
      !contact.supportKind ||
      !contact.body
    ) {
      setError(true)
      return
    }
    onConfirm()
  }

  return (
    <>
      <OfficialLowHeader
        label="ご利用サポート"
        description="以下のフォームに必要事項をご入力の上、確認ボタンを押してください"
      />
      <Wrapper>
        <div className="p-section-inner">
          <ContactFlow className="now-01" />
          <div className="p-top-data-wrap">
            <p>
              キンクラをご利用中に困った時は、こちらのヘルプページをご確認ください。
            </p>
            <OfficialCommonBtn
              size="small"
              level="weak"
              onClick={() => {
                window.open(routeParam)
              }}
            >
              ヘルプ
            </OfficialCommonBtn>
            <hr />
            <p>
              お客様からよくお問い合わせいただくご質問を、Q&amp;A形式でご案内しています。
            </p>
            <OfficialCommonBtn
              size="small"
              level="weak"
              onClick={() => {
                navigate(routeParam2)
              }}
            >
              よくあるご質問
            </OfficialCommonBtn>
          </div>
          <div className="p-section-contact">
            {/** 会社名 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">会社名</span>
                <span className="txt-color-alert txt-size-small require">
                  *必須
                </span>
                {isError && !contact.company && (
                  <span className="txt-color-alert txt-size-small err">
                    会社名を入力してください。
                  </span>
                )}
              </p>
              <input
                value={contact.company}
                onChange={onChange('company')}
                placeholder="○○株式会社"
              />
            </div>

            {/** 企業ID */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">企業ID</span>
                <span className="txt-color-alert txt-size-small require">
                  *必須
                </span>
                <span className="sub-description txt-size-small">
                  「設定管理＞企業設定」から確認可能です。
                </span>
                {isError && !contact.companyId && (
                  <span className="txt-color-alert txt-size-small err">
                    企業IDを入力してください。
                  </span>
                )}
              </p>
              <input
                value={contact.companyId}
                onChange={onChange('companyId')}
                placeholder="dcs9999999"
              />
            </div>

            {/** 氏名 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">ご担当者名</span>
                <span className="txt-color-alert txt-size-small require">
                  *必須
                </span>
                {isError && !contact.name && (
                  <span className="txt-color-alert txt-size-small err">
                    氏名を入力してください。
                  </span>
                )}
              </p>
              <input
                value={contact.name}
                onChange={onChange('name')}
                placeholder="田中太郎"
              />
            </div>

            {/** メールアドレス */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">登録メールアドレス</span>
                <span className="txt-color-alert txt-size-small require">
                  *必須
                </span>
                {isError && !contact.email && (
                  <span className="txt-color-alert txt-size-small err">
                    メールアドレスの形式が間違っています。
                  </span>
                )}
              </p>
              <input
                value={contact.email}
                onChange={onChange('email')}
                placeholder="info@sample.jp"
              />
            </div>

            {/** お問い合わせ種別 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">お問い合わせ種別</span>
                <span className="txt-color-alert txt-size-small require">
                  *必須
                </span>
                {isError && !contact.supportKind && (
                  <span className="txt-color-alert txt-size-small err">
                    お問い合わせ種別を選択してください。
                  </span>
                )}
              </p>
              <div className="p-select">
                <select
                  required
                  value={contact.supportKind}
                  onChange={onChange('supportKind')}
                >
                  <option value="" hidden>
                    選択してください
                  </option>
                  {SupportKindList.map((val) => (
                    <option key={val.kind} value={val.kind}>
                      {val.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/** お問い合わせの詳細 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">お問い合わせの詳細</span>
                <span className="txt-color-alert txt-size-small require">
                  *必須
                </span>
                {isError && !contact.body && (
                  <span className="txt-color-alert txt-size-small err">
                    お問い合わせ内容をご記入ください。
                  </span>
                )}
              </p>
              <textarea
                value={contact.body}
                onChange={onChange('body')}
                placeholder="お問い合わせされたい具体的な内容をご記入ください"
              />
            </div>

            {/** 利用環境 */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">利用環境</span>
              </p>
              <div className="p-select">
                <select
                  required
                  value={contact.deviceKind}
                  onChange={onChange('deviceKind')}
                >
                  <option value="">選択してください</option>
                  {DeviceKindList.map((val) => (
                    <option key={val.kind} value={val.kind}>
                      {val.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/** エラーメッセージ */}
            <div className="item-wrap">
              <p className="label-area">
                <span className="txt-bold">エラーメッセージ</span>
              </p>
              <textarea
                value={contact.error}
                onChange={onChange('error')}
                placeholder="エラーメッセージが表示される場合はメッセージ内容をご記入ください"
              />
            </div>

            {/** プラポリ同意 */}
            <div className="txt-center">
              <CommonCheckbox
                checked={contact.agree}
                onChange={onChange('agree')}
              >
                <a
                  href="https://www.clinks.jp/policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  プライバシーポリシー
                </a>
                に同意する
              </CommonCheckbox>
              {isError && !contact.agree && (
                <span className="txt-color-alert txt-size-small err">
                  プライバシーポリシーに同意してください。
                </span>
              )}
            </div>

            {/** 内容確認 */}
            <OfficialCommonBtn
              size="large"
              level="normal"
              className="p-btn-contact"
              onClick={onClick}
            >
              送信内容を確認する
            </OfficialCommonBtn>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
export default OfficialSupport01
